const Header = () => {
  return (
    <ul className="heading">
      <li>Casino</li>
      <li>Bonus</li>
      <li>Rating</li>
      <li>Min Deposit</li>
      <li>Play Now</li>
    </ul>
  );
};
export default Header;
