import Header from './Header';
import BetRivers from 'assets/betrivers.svg';
import FanDuel from 'assets/fanduel.svg';
import BetMGM from 'assets/betmgm.svg';
import Caesars from 'assets/caesars.svg';
import Star from './Star';
import { Brandlist } from 'mock/BrandList';
interface IProps {
  header?: boolean;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ImgObj: any = {
  BetRivers: BetRivers,
  FanDuel: FanDuel,
  BetMGM: BetMGM,
  Caesars: Caesars,
};
const Brand = (props: IProps) => {
  const { header = false } = props;

  return (
    <div className="brandWrapper">
      {header && <Header />}
      {Brandlist.map((item) => {
        return (
          <>
            <div className="brandRow" key={item.id}>
              <a href={item.url} className="brandImg" target="_blank" rel="noreferrer">
                <img src={ImgObj[item.name]} />
              </a>
              <div className="bonusText">{item.bonusText}</div>
              <div className="rating">
                <div className="ratingText">
                  Rating <span>(4441)</span>
                </div>
                <div className="ratingStar">
                  <Star />
                </div>
              </div>
              <div className="minDeposit">10$</div>
              <a href={item.url} className="btnPlay" target="_blank" rel="noreferrer">
                Play Now
              </a>
            </div>
            {/* mobile */}
            <div className="brandRowMob" key={item.id}>
              <div className="brandwrapperMob">
                <a href={item.url} className="brandImg" target="_blank" rel="noreferrer">
                  <img src={ImgObj[item.name]} />
                </a>
                <div className="rating">
                  <div className="ratingHeader">Rating</div>
                  <div className="ratingText">
                    Rating <span>(4441)</span>
                  </div>
                  <div className="ratingStar">
                    <Star />
                  </div>
                </div>
              </div>
              <div className="bonusWrapperMob">
                <div className="bonusHeader">Bonus</div>
                <div className="bonusText">{item.bonusText}</div>

                <div>
                  <div className="minDepositHeader"> Min Deposit</div>
                  <div className="minDeposit">10$</div>
                </div>
                <a href={item.url} className="btnPlay" target="_blank" rel="noreferrer">
                  Play Now
                </a>
              </div>
            </div>
          </>
        );
      })}
    </div>
  );
};

export default Brand;
