import { Container } from '@mui/material';
import Person1 from 'assets/person1.jpg';
import Win1 from 'assets/win1.jpg';

import Logo from 'assets/Logo.svg';
import { useEffect } from 'react';

const BetMgm = () => {
  const referringDomain = document.referrer;
  const handleClick = () => {
    _tfa.push({ notify: 'event', name: 'click_out', id: 1569015 });
    // Add any other logic you want to execute on the click event
  };

  useEffect(() => {
    // Make sure that the _tfa object is available before rendering the component
    if (typeof _tfa !== 'undefined') {
      console.log('_tfa', _tfa);
      _tfa.push({ notify: 'event', name: 'page_view', id: 1569015 });
    }
  }, []);

  return (
    <>
      <div className="  text-white lg:text-5xl p-4 fixed w-full" style={{ backgroundColor: '#5ca4d8' }}>
        <Container maxWidth="md" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <img src={Logo} />
          <a
            className=" text-base bg-white p-2 rounded-lg text-black button "
            href={`https://captrkr.com/track/0e753fe3-1e80-4ea4-bdf7-e64dcdaf96d7?type=ppc&s1=TB01BEM620MI&tt=${referringDomain}`}
            target="_blank"
            rel="noreferrer"
            onClick={handleClick}
          >
            Claim Bonus
          </a>
        </Container>
      </div>
      <Container maxWidth="md">
        <p className="text-4xl text-left my-10 font-bold mt-28">
          Why are numerous individuals opting to engage in online casino games rather than participating in the lottery?
        </p>
        <p className="text-left font-bold mb-6">Julia Robert — June 17, 2023</p>
        <p className="text-xl text-left">
          Are you weary of repeatedly selecting the same lottery numbers without experiencing substantial winnings?
          It&apos;s time to make a change and transition to the casino, where a multitude of individuals are
          enthusiastically converging to seize their opportunity for wealth!
        </p>
        <p className="text-left my-4">
          Online casino games present an assortment of game options, each with diverse degrees of skill and chance,
          enabling you to discover the ideal game that matches your preferences and proficiency. Moreover, the potential
          winnings in casino games surpass those of the lottery by a considerable margin, offering you the opportunity
          to secure a substantial sum of money that can truly transform your life.
        </p>
        <a
          href={`https://captrkr.com/track/0e753fe3-1e80-4ea4-bdf7-e64dcdaf96d7?type=ppc&s1=TB01BEM620MI&tt=${referringDomain}`}
          target="_blank"
          rel="noreferrer"
        >
          <img src={Person1} />
        </a>
        <p className="text-left my-6">
          It&apos;s not solely about the monetary aspect. Online casinos also offer a vibrant and captivating atmosphere
          where you can interact with fellow players and relish in the social aspect of gambling. It provides an
          opportunity to connect with others, enhancing the overall experience and making it more enjoyable and
          engaging.
        </p>
        <a
          href={`https://captrkr.com/track/0e753fe3-1e80-4ea4-bdf7-e64dcdaf96d7?type=ppc&s1=TB01BEM620MI&tt=${referringDomain}`}
          target="_blank"
          rel="noreferrer"
        >
          <img src={Win1} className="" />
        </a>
        <p className="text-left my-6">
          Furthermore, reputable online casinos prioritize state-of-the-art security measures and implement fair play
          policies, ensuring that your gaming experience is both secure and equitable. This commitment to security and
          fairness provides peace of mind, allowing you to enjoy your gaming sessions without any concerns.
        </p>
        {/* <Modal
          imgName="BetMGM"
          url="https://captrkr.com/track/0e753fe3-1e80-4ea4-bdf7-e64dcdaf96d7?type=ppc&s1=TB01BEM620MI"
          bonusText="100% Bonus up to $1000 + $25 Token"
        /> */}
        <p className="text-left my-6">
          Why settle for the monotonous routine of the traditional lottery when you have the opportunity to join the
          vast community of contented players at Ireland&apos;s top casinos? Embrace the exhilaration of the game,
          immerse yourself in the electrifying atmosphere, and seize the chance to secure significant winnings today!
          Don&apos;t miss out on the excitement and potential rewards that await you in the world of online casinos.
          Join now and elevate your gaming experience to new heights!
        </p>
        <h1 className="text-left text-xl">Are you prepared to claim your guaranteed welcome bonus?</h1>
        <div className="my-6">
          Follow the steps below:
          <p className="text-left">
            Start your journey today by signing up and gain access to a vast selection of over 1000+ of your favorite
            slots and Live Casino games. As an added bonus, you&apos;ll receive a 100% Casino Bonus to enhance your
            gaming experience. Don&apos;t miss out on this opportunity to enjoy thrilling gameplay and maximize your
            chances of winning. Sign up now and let the excitement begin!
          </p>
          <ol className="p-4">
            <li>1. Sign up quickly and effortlessly to create your account.</li>
            <li>2. Utilize the code GLHF and deposit funds using our reliable payment methods.</li>
            <li>3. Begin playing with real money and enjoy an entertaining experience!</li>
          </ol>
        </div>
        <a
          href={`https://captrkr.com/track/0e753fe3-1e80-4ea4-bdf7-e64dcdaf96d7?type=ppc&s1=TB01BEM620MI&tt=${referringDomain}`}
          target="_blank"
          rel="noreferrer"
        >
          <img src={Win1} />
        </a>
      </Container>
      <div className="text-white lg:text-5xl p-4 mt-6" style={{ backgroundColor: '#161616' }}>
        <Container maxWidth="md" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
          <img src={Logo} />
          <div className="disclaimer">
            * Disclaimer: This content is promotional in nature and should not be considered a genuine news article. The
            product mentioned is intended for amusement purposes only and is recommended for individuals aged 21 and
            above. The trademarks used on our website are the property of their respective owners, and we do not imply
            or express any endorsement of our website or services. Our team conducts thorough research and relies on
            experienced editors to provide feedback on various products and services. We operate independently, and the
            opinions expressed here are our own. Please note that gambling through the mentioned brands and offers is
            exclusively available to users residing in the state of Michigan.
          </div>
        </Container>
      </div>
    </>
  );
};

export default BetMgm;
