import { Container } from '@mui/material';

export const Terms = () => {
  return (
    <Container>
      <p>
        Gambling can be addictive, please play responsibly Terms and conditions of Use Version number 1.8, last updated
        28/09/2020 Please read these terms and conditions carefully before accepting this agreement and before using
        this site. Make sure to print these terms and conditions and keep them for your records.
      </p>
      <p>&nbsp;</p>
      <p>
        Introduction We consider these terms to be fair. Should you need any advice regarding these terms, or any other
        part of our service, in your own and our interests, please contact us via our Customer Services Department via
        the support link on the site and your trusted legal advisor. In these terms and conditions the licensed entity
        (which is noted above the words ‘Terms and conditions of use’ which is located above) is referred to as ‘We’,
        ‘Us’, or any other name of similar purport and you are referred to as ‘customer’, you, user, player or any other
        term of similar purport.
      </p>
      <p>&nbsp;</p>
      <p>
        Please note that all telephone calls are digitally recorded and may be used as evidence at Our sole discretion.
        For transaction security we use SSL encryption. All customer data will be treated as confidential and will not
        be sold to third parties. By ticking the ‘I agree to the terms and conditions’ box when you register an account
        to play OJO&apos;s online casino games, you acknowledge that you have read, understood, agree with and will
        comply with all the terms and conditions and you thereby enter into a legally binding agreement between you and
        Us. If you do not agree with any of the terms and conditions you should not accept them. Once you have agreed to
        these terms and conditions, Your failure to comply with any one or more of them may result in disqualification,
        account closure, and/or legal action against you as deemed appropriate by Us. Where we have reasonable grounds
        to suspect that any two or more registered accounts are linked including whether they are linked to the same
        internet protocol (“IP”) address, pattern of use, proximity of transactions, common bank details or address in
        common or otherwise and that the services provided via the Site are being abused of by you in any way, including
        by way of example due to a previously undetected fault, bug or other fault in the software, we may suspend the
        services provided to you via the site and your ability to access your account(s) with immediate effect as well
        as to reverse transactions on any one or more of the accounts that are linked By using this Site and/or software
        you agree to these terms and conditions and any other terms or conditions that We see fit to impose from time to
        time. These terms and conditions are written in English.
      </p>
      <p>&nbsp;</p>
      <p>
        If these terms and conditions are translated into another language, the English version will prevail. Your
        participation in the Games is at your sole discretion and risk. By accepting these terms and conditions you
        agree that you do not find the services we provide through our Site and/or software offensive, unfair or
        questionable. You also acknowledge that We are unable to provide you with any legal advice or assist you with
        any Legal queries related to the legality of participation in remote gambling. All fees and charges displayed in
        this document are in C$ (&quot;CAD&quot;). We reserve the right to restrict access to all or certain parts of
        the Site in respect of certain jurisdictions. Unless stated otherwise, whenever a time is mentioned it refers to
        GMT.
      </p>
      <p>&nbsp;</p>
      <p>
        In these terms, whenever reference is made to a Balance this may refer to Real Money balance or OJO Plus
        balance, or both. We believe in Fair Play, hence there are no wagering requirements on any of our rewards or
        offers. Since we are fair, we expect you to be too, and in order to guide you on our expectations we have
        prepared the following Fair Play Policy. Any internal investigation which uncovers noncompliance of this Fair
        Play Policy, which will be determined by Us in Our sole opinion, may result in the removal of funds from your
        balance, suspension of any current or future Reward or closure of your account and refusal of any of your future
        accounts. Any of the below patterns and behaviours are considered Anti Fair Play Accruing OJO Plus or Unlocking
        Levels and receiving OJO Wheel Rewards by using low risk betting patterns (low margin betting, equal betting,
        zero risk bets, hedge betting, doubling systems.
      </p>
      <p>&nbsp;</p>
      <p>
        For the avoidance of doubt this includes using low risk betting patterns either alone or by colluding with
        others, either on this online casino or with one party on this casino and another party on a different online
        casino Depositing purely to take advantage our Rewards/Offers and with no intention of playing /risking the
        funds you deposited with. Colluding in any way with others in order to take advantage of Rewards or offers. We
        reserve the right to limit/refuse bets. We reserve the right to adjust the minimum/maximum bets within the
        casino. The only approved way to send verification documents is via the Upload Docs page in My Account/Personal
        Area. Documents received by any other avenue will not be accepted.
      </p>
      <p>
        During your time as a customer You will be required to upload documentation proving your ID, registered address
        and ownership of certain deposit methods. For example, when certain thresholds are reached or prior to allowing
        a withdrawal to be processed. We encourage you to upload these documents as early as possible to avoid an
        uninterrupted experience. Legal Requirements for Playing the Game You must be at least 18 years of age, or the
        minimum legal age in the jurisdiction in which you are viewing this Site or using the services offered via our
        Site, providing the minimum age in your jurisdiction is not less than 18. Please be aware that underage gambling
        is illegal. We reserve the right at any time to request from you evidence of age in order to ensure that minors
        are not using our services. The documents we accept as proof of age are: Copy of passport; Copy of driving
        license; and/or Copy of an official ID Card Only players who are resident in countries in which gaming is
        allowed are permitted to deposit money into a Real Money account, or play in Real Money mode. Players from
        United Kingdom, Greece, Australia, Portugal, Lithuania, Slovakia, Serbia, Singapore, Iran, Czech Republic,
        Slovenia, Estonia, Romania, the Republic of Cyprus, Italy, Israel, Turkey, the German State of
        Schleswig-Holstein, France, Switzerland, Mexico, Spain, Denmark, Belgium, Philippines and the USA are not
        permitted to deposit any money into a Real Money account, nor play in Real Money mode. Remote gambling is
        illegal in some jurisdictions. By accepting these terms and conditions you acknowledge that you have checked
        legality in your jurisdiction and it is legal to gamble online. Players from Bulgaria and Slovakia are not
        permitted to play any of the games within the Live Casino section of the lobby. By playing the Games (online
        slots, card games...), you acknowledge that you are doing so according to the laws that apply in the
        jurisdiction in which you are viewing this Web Site and/or using the software.
      </p>
      <p>&nbsp;</p>
      <p>
        Information We Collect About You and How We Use It We process information about you in accordance with our
        privacy policy. Our privacy policy forms part of these terms and conditions and contains details on the types of
        information we collect and what we do with that information, which includes who it may be shared with and why.
        We are entitled to share the information we hold on you, which includes personal data and gaming history with
        regulators, and other bodies, including the police, and law enforcement bodies in order to investigate fraud,
        money laundering gaming integrity issues and to comply with our regulatory duties. Grant of License The Site,
        our apps and the Site as optimized for use on mobiles and tablets, all run on a series of programs which are
        either proprietary to us or are licensed through third parties to be able to offer you our services through the
        Site (“Software”). We grant you a license to use a single copy of the Software and where applicable the related
        documentation solely in accordance with and subject to these terms. You may neither sell, rent, lease, transfer
        or grant any other right of the Software, nor reverse engineer, decompile, or dissemble the Software. You may
        not disclose the Software to any third party, make derivative works based upon it, or use it for any purpose
        except for the specific uses expressly permitted in the Player documentation, which accompanies the Software.
        The Software is available to you on an &quot;As Is&quot; basis only and without any warranty or indemnity of any
        kind. The download and/or use of the Software and Services is at your own risk. You hereby acknowledge that how
        you use the Software is outside of our control. We shall not be liable to you or to any third party in respect
        of your receipt of and/or use of the Software
      </p>
      <p>&nbsp;</p>
      <p>
        . Real Money Mode and Try for Free Mode By using the services offered through our Site, you will be able to play
        in either the &apos;Real Money&apos; mode or &apos;Try for Free&apos; mode. In &apos;Real Money&apos; mode, real
        money is used. In order to play in &apos;Real Money&apos; mode you are required to have free spins, or funds in
        your balance, or pay funds into your account (which has the meaning described in clause 7) by any of the methods
        specified from time to time by Us. Such funds will be deposited into your Account upon actual receipt of funds
        or upon receipt of approval from a payment processor. Minimum and maximum limits may be applied to the payments
        into your account, depending upon your history, the method of deposit, and other factors as determined solely by
        the Us. We are not a bank and funds are not insured by any government agency. All payments to and from your
        Account must be paid in the currencies listed in the cashier from time to time and shall not bear interest. All
        payments into your account must be from a payment source on which you are the named account holder. In &apos;Try
        for Free&apos; mode, no real money is used. Funds played in &apos;Try for Free&apos; mode have no value in and
        of themselves and are kept separate from Real Money funds. Neither funds nor any game features (for example, but
        not limited to Multipliers, Bonus games and Bonus Points) are transferable to a Real Money account nor are they
        redeemable for any currency. If any game features are incorrectly transferred from Try for Free Mode to Real
        Mode any winnings derived will be removed. If you notice the transfer of any game feature from Try for Free Mode
        to Real Mode it is your responsibility to inform Us immediately. Try for Free Mode can be accessed per game (if
        available for that game) by choosing to ‘Try For Free’ on the game icon. Once you close the game, you are no
        longer in Try for Free Mode It is not advisable to play &apos;Real Money&apos; mode before trying &apos;Try for
        Free&apos; mode. We recommend players practice in &apos;Try for Free&apos; mode prior to playing in &apos;Real
        Money&apos; mode. It is your responsibility to make sure that you read and understand all the rules and
        procedures of the games on the Site(s) prior to playing real money games.
      </p>
      <p>&nbsp;</p>
      <p>
        You hereby acknowledge and agree that using our services might result in losing monies and that all losses
        incurred will be Your sole responsibility. The games RTP is displayed in the game rules within each game and is
        subject to change. If the RTP is an important factor in your decision whether to play a particular game we
        recommend checking the game rules each time you play. Player Accounts The information you provide during account
        registration must be true, complete and up to date. You must provide correct details which belong to you.
        Amongst other things you will be required to enter a valid address, mobile phone number and contact e-mail
        address during the registration process. A Player is not allowed to register more than one account. This means
        only one account is allowed to be registered per person, household, family, household address, email address,
        credit card number or shared computer environment (i.e library, workplace, fraternity, university or school
        etc’). Any withdrawal made from a duplicate account will be considered unauthorised and will be claimed back.
        Any attempt to try and deceive Us by registering multiple accounts in another Player name to gain rewards will
        be deemed as abuse, and will result in the account(s) being suspended, Reward money being removed, winnings
        voided and the option to withdraw monies canceled.
      </p>
      <p>&nbsp;</p>
      <p>
        All information supplied when you register with the site MUST be accurate, complete and not misleading in any
        respect. We reserve the right to confirm a customer&apos;s address by any means available, including but not
        limited to posting an address verification letter by mail to you, contacting you by e&shy;mail, phone or live
        chat. If the attempt to verify the details supplied during registration is not successful no winnings will be
        paid. If the details you supplied at registration change, you must update us and send us proof of the new
        details. There are two types of “Accounts”: Non Real Account: A Non Real Account is an account which has not
        been funded by a Deposit. Upon receipt of Your registration we will set up a Non Real Account for You. Real
        Account: In order to change status to a Real Money account, a minimum deposit amount is required as well as the
        initial registration requirements. The minimum deposit requirement is subject to change at any time. Your
        Account is for your personal use only. You may not divulge Your Account details to anyone else or use anyone
        else account details. Any use of Your Account is strictly for your own private purposes. You shall be solely
        responsible for all transactions conducted in relation to your account using your Username and Password. Every
        person who identifies himself/herself by entering Your correct Username and Password, or logs into Your account
        using biometric login functions is assumed by Us to be You and all transactions where the Username and Password
        have been entered correctly will be regarded as valid and authorized by You We take absolutely no responsibility
        for any third party accessing Your Account using the correct Username and password and we shall not be liable
        for any losses incurred by you as a result of such an event. Any access to Your Account with the correct
        Username and password will be considered by us as valid. Should you become aware of any unauthorized use of Your
        Account, you are obliged to immediately notify us and we will suspend your Account pending further investigation
        of such unauthorised use. To help prevent un-authorized account use, We recommend that you do not use any
        functionality which saves your password for you. Your account is not a banking facility. Deposits should only be
        made with a view to using funds to place bets. Should You make repeated deposits and withdrawals without placing
        a commensurate amount of bets, or placing all low or very low risk bets, we reserve the right to pass on to your
        accounts, without prior notice, any bank charges (15% of the amount) we have incurred before closing the
        account. Account facilities are provided to You solely to enable you to place bets or participate in games. If
        you, for whatever reason, appear to be depositing or withdrawing money without genuine play, you will be liable
        to have your account suspended and the circumstances investigated. This may result in a report to the necessary
        authorities and/or the account being closed.
      </p>
      <p>&nbsp;</p>
      <p>
        You may terminate the services on Your account by utilizing our Play Limitation function within the OJO
        SafeGuard section of My Account In case the reason behind the closure of the Account is related to concerns
        about possible gambling addiction or you wish to extend the limitation period, You shall inform us via email
        after placing your Limitation. You hereby confirm that you are not using or intending to use or intending to
        allow any other person to use your account in relation to Our services for any prohibited or unlawful activity,
        including but not limited to, fraud or money laundering, under the laws of your jurisdiction or any other
        applicable laws in any other jurisdiction. You hereby confirm that You have not held a Player Account with Us
        that was terminated or suspended, and You have not notified Us, now or in the past, that You have a gambling
        addiction. You hereby confirm that You shall not be involved in any fraudulent, collusive, fixing or other
        unlawful activity in relation to Your or third parties&apos; participation in any of the Games. You agree that
        if, in Our opinion, if any suspicious, improper, fraudulent, or unlawful activities are attempted and/or
        performed through the use of Our Services, We will be entitled to terminate and/or block your account and seize
        all funds available through that account. We also reserve the right to disclose any and all details of your
        account to the relevant authorities. In the event that you become aware that another Player of Our services is
        cheating, manipulating or attempting to manipulate game outcomes and results, colluding with any other person in
        order to defraud Us, using Artificial Intelligence Software, or doing anything which can be construed as
        detrimental to us, financial or otherwise, You are responsible for sharing such information with us.
      </p>
      <p>&nbsp;</p>
      <p>
        We will treat any such information You pass to Us in the strictest of confidence. In the event that we discover,
        or suspect, that you had such knowledge and did not pass it on to Us, we reserve the right to terminate Our
        services to You and confiscate any funds which are in Your account. Transactions: Deposits Unless otherwise
        stated in the relevant casino pages of the Site, minimum deposit amounts are fixed for each currency: AUD - $ 10
        CAD - $ 10 CHF - chf 10 DKK - kr 100 EUR - € 10 GBP - £ 10 NOK - kr 100 RUB - 500 p. USD - $ 10 SEK - 100 kr ZAR
        - R 100 After depositing, you will receive email confirmation of the transaction. We recommend that you print
        out and keeps details of all transaction data in order to avoid misconceptions and discussions at a later time.
        All deposits will be effective immediately as soon as we get a successful confirmation from the selected payment
        processor (except for Wire Transfer Deposits). It is unlawful to deposit money derived from ill-gotten means.
        Please note that documentation in the form of ID, utility bill/statement or copies of the front of your credit
        card may be required after any deposit. We reserve the right to contact you to verbally verify any deposit made.
        We reserve the right to void all winnings if your name does not match the name on the credit card you use. In
        such circumstances we reserve the right to request the necessary documentation from the player to enable us to
        carry out the required identity verification checks from our end, prior to taking any action. The burden of
        proof that the player had the necessary authorisation to proceed with the transaction shall vest in the player
        at all times. Players who make a credit card deposit using someone else’s credit card will be requested to
        provide copies of the credit card, ID documents from the credit card holder and Account holder and will be sent
        via email a Letter of Authority form.
      </p>
      <p>&nbsp;</p>
      <p>
        This form must be signed and completed by the Credit Card Holder and the Account holder. We will carry out KYC
        procedures when you make a deposit We reserve the right to request identity documents after any deposit. Any or
        all of the following Documents may be required in accordance with our KYC (Know Your Customer) obligations Copy
        of identification card(s) Copy of Driver’s License Copy of the front of the credit card being used to make the
        deposit (middle 8 digits may be blanked out) Copy of up-to-date Utility Bill clearly showing name and address
        Bank statement Signed Deposit Confirmation Form Credit card statement (Showing the recent transactions which
        were made to Us) If you were required to provide documentation as explicitly described in Section 7 or 8 of this
        agreement and you have failed to comply, your Account may be suspended until verification is completed. No
        charge-backs can be made or initiated by you. In addition, payments including deposits made to your Account
        using any payment method/source cannot be renounced, cancelled, disputed for no valid reason or otherwise
        reversed. If this happens we reserve the right to take any of the following actions or request you to take
        immediate action, whichever the case may be, as a necessary measure to safeguard us against possible fraudulent
        activity or any other form of abuse:- You shall immediately and without undue delay refund the total amount due
        to us and compensate Us for any and all unpaid amounts due to us including any expenses or charges We might
        incur in the process of recovering the amount due; Should you initiate a chargeback, we reserve the right to
        close your Account, initiate legal proceedings to recover the funds you are trying to chargeback as well as any
        charges or expenses which we might incur as a result and report the matter to the Police if we suspect foul
        play; We reserve the right to recuperate the total amount due to us from your balance. We may, at any time, set
        off any positive balance on your Account, or connected Account, against any amounts owed by you to Us. In the
        case that there is a processing charge for your deposit you will be clearly informed about it during the deposit
        process. The following methods are available for depositing to your account. Note that some methods are not
        supported in some countries but in the casino software you can see the methods available for your country.
        Credit/Debit Card (Visa, Mastercard, Maestro/Switch/Solo), PayPal, Neteller Skrill, ECOPayz, Fast Bank Transfer,
        PaySafeCard, Sofort, Instant Payments by Citadel, INTERAC, , Webmoney, . We reserve the right to add/remove
        deposit methods with immediate effect and without prior warning
      </p>
      <p>&nbsp;</p>
      <p>
        . Transactions: Withdrawals There is no minimum amount for withdrawal. For withdrawals less than C$5, we reserve
        the right to send a cheque. Withdrawals will only be processed if a player has a Real Money Account (i.e. made
        at least one successful deposit), has provided us (and we have approved) copies of proof of address, proof of ID
        and, method depending, proof of ownership of the payment method used, plus evidence of the source of funds used
        for gambling. In order to have an uninterrupted gaming experience, we suggest you upload these prior to placing
        a withdrawal. Also the player must have sufficient real money balance for the withdrawal, and fulfilled any
        applicable requirements as detailed in Our Rewards and Game Play Policy. Withdrawals will be remitted only to
        the same method used for deposits, unless the depositing method does not allow withdrawals. Withdrawals can only
        be made based upon the initial registration information provided by the player. Incorrect or misleading data in
        personal details and/or contact details such as phone number or email address will result in all winnings being
        voided. This is aside from any penalties or actions taken by Us due to any breach of these terms and conditions.
        There is a maximum amount per withdrawal, the details of which can be found within the withdrawal page inside
        the casino client. Please also note that if there is a large win (C$5,000 or more), or jackpot win it may take
        longer to process the request in order to verify the win (with a third party where relevant) and to complete all
        required checks. When you make a withdrawal request, we shall insofar as practical pay you via your preferred
        payment method. However we reserve the right to pay you using any one or more of the payment methods listed in
        the cashier, whether it is one of your preferred payment options or otherwise. When a deposit is made via Credit
        Card, all subsequent withdrawals at least of the amount deposited will be paid back to the Credit Card where
        possible. Any money we can’t deposit to your card will be paid to an alternative method of your choice. When a
        deposit is made via an Online Wallet account, the amount deposited will be paid back to that same Online Wallet
        account. Any payments of funds made to you from Us may be subject to a surcharge to cover costs, such as in the
        case of Wire Transfers, which We will deduct from your balance before processing the withdrawal. The surcharges,
        and withdrawal times, which are subject to change, are as follows: Wire Transfer - C$10* (over C$500 no charge)
        – between 2 and 7 business days Credit/Debit cards (Visa, Maestro/Switch/Solo) – No charge - between 2 and 7
        business days Online Wallets (EcoPayz, Skrill, Neteller, PaySafeCard) – No charge – same day In certain
        countries we have the ability to process withdrawals via Trustly/Entercash within a few minutes, providing we do
        not require any verification documents to process the withdrawal. Should any further documents or information be
        needed, the withdrawal will be pending while we gather the information from you. Transfer of funds between
        players is strictly forbidden.
      </p>
      <p>&nbsp;</p>
      <p>
        We aim to process all withdrawals within a few hours and in order to help make this goal we recommend that you
        send us/upload any necessary documentation so as not to delay the process. You may modify or reverse your
        request to withdraw funds at any time until your withdrawal has been completed and fully processed at which
        point your funds will be returned to your balance Before any withdrawals are processed, your play will be
        reviewed for any breach of terms and conditions/ Rewards and Game Play Policy and Responsible Gaming checks.
        Should We deem that a breach has occurred, or you have another account which is restricted due to our
        Responsible gaming tools/procedures, we reserve the right to withhold any withdrawals and/or confiscate all
        winnings and rewards. Any contact by Us congratulating any win does not mean the win is legitimate and that the
        Accounts Department have checked for Reward and Game Play/terms breaches. In the event that you fail to comply
        with any of our requests to provide KYC documents, we reserve the right to reverse the withdrawal back into your
        Account. Withdrawals will be investigated in line with any or all applicable legislation on Anti Money
        Laundering and counter terrorism funding practices. You fully accept that We are required to report any
        suspicious transactions to relevant financial intelligence units and in doing so will disclose information
        regarding your personal information and deposit and withdrawal transfers. If We suspect, or Our interpretation
        of any legislation or guidelines leads us to believe that any transaction you have made may be connected to
        money laundering or funding of terrorist activities your Account will be frozen along with any deposits, Rewards
        or winnings pending such time as we are authorized to release them by the bodies or authorities we report
        suspicious transactions to, or otherwise by order of a court of competent jurisdiction.
      </p>
      <p>&nbsp;</p>
      <p>
        We reserve the right to delay and/or stop the processing of a Withdrawal until final approval has been received
        for any outstanding Deposit transaction or in the event that your original deposited funds are not received
        within 24 hours. If the original funds are not received within 24 hours all winnings will be canceled Withdrawal
        requests are only deemed valid if they are submitted via the withdrawal page in the casino software. We do not
        accept withdrawal requests via email or telephone, or any other method. Rewards You acknowledge and understand
        that our terms and conditions with respect to promotions, Rewards and special offers form an integral part of
        these terms and conditions. These terms and conditions are set forth in Our Rewards and Game Play Policy In the
        event of a conflict between the provisions of our terms and conditions on promotions, Rewards and special
        offers, and these terms and conditions, the provisions of such promotions, Rewards and special offers will
        prevail. Malfunctions If a technical problem causes a game/feature/promotion to pay out (or a display issue
        causes wrong result to be displayed) a higher amount than should be paid (a game win, bonus game win, jackpot
        win etc) to a player&apos;s Account, We reserve the right to remove all funds (or not pay the incorrectly
        displayed amount) from the Account that were incorrectly credited as well as any winnings subsequently won by
        using the incorrectly credited funds If We detect a Player has or is abusing a technical glitch related to the
        games/software, either on his own or in association with partners, We reserve the right to void any winnings and
        close the relevant player/s Accounts. In the event of a system malfunction all wagers shall be void. In the
        event a game is started but miscarries because of a failure of the system, We shall refund the amount wagered in
        the game to You by crediting it to the Your Account or, if the account no longer exists, by paying it to You in
        an approved manner. We shall take all reasonable steps to ensure that Our system enables Your participation in a
        game is, after You have made a wager, interrupted by a failure of the telecommunications system or a failure of
        Your computer system that prevents You from continuing the game, to resume, on the restoration of the system,
        your participation in the game that was interrupted as at the time immediately before the interruption. If Our
        system does not enable You to continue, after the restoration of the system, with a game interrupted by a
        failure of the telecommunications system or Your computer system, We shall: (a) ensure that the game is
        terminated; and (b) refund the amount of the wager to You by placing it in the Your Account. Services Suspension
        We may temporarily suspend the whole or any part of the Services for any reason at our sole discretion. We may,
        but shall not be obliged to, give you as much notice as is reasonably practicable of such suspension. We will
        restore the Service, as soon as is reasonably practicable, after such temporary suspension. Change We reserve
        the right to suspend, modify, remove or add content to the Site or the services offered via the Site at Our sole
        discretion with immediate effect and without notice. We shall not be liable to you for any loss suffered as a
        result of any such changes made and you shall have no claims against Us in such regard. Currencies The following
        currencies are available for deposit, withdrawal or play. By default, on account registration we assign the most
        logical currency according to where we believe you are registering from. There are no facilities to change this
        currency however if you make a first deposit in any of the other currencies your account currency will be
        updated to that one. GBP, EUR, CHF, USD, AUD, CAD, DKK, SEK, NOK, ZAR and RUB.
      </p>
      <p>&nbsp;</p>
      <p>
        Artificial Intelligence Software You agree not to use any artificial intelligence software (&apos;AI
        software&apos;) while using the software and/or Site. We will act to detect and prevent the use of any AI
        software programs that enables you to have an unfair advantage over other players, including, but not limited
        to, detecting the running of such programs on your computer. In the event that we detect the use of AI software,
        and at our sole discretion deem the detection to be valid, your Account will be terminated and all winnings
        cancelled. Random Number Generator You accept and agree that a random number generator will determine the
        randomly generated events required in connection with the Services and where the result shown on the Software
        (as installed and operated by your hardware) conflicts with the result shown on our, or our game partners
        servers, the result shown on our server shall in all circumstances take precedence. You understand and agree
        that (without prejudice to your other rights and remedies) the Company records shall be the final authority in
        determining the terms of your use of the Services and you shall have no right to dispute the Company&apos;s
        decisions in regard to such matters. Abusive or Offensive Language We will not tolerate any abusive or offensive
        language or other behaviour considered inappropriate by Us at our sole discretion. Bad behaviour or language
        identified by Us or brought to Our attention may result in a suspension or loss of playing privileges or such
        other action as may be decided by Us, including, but not limited to, Account closure. It is forbidden for
        players to use offensive Usernames or Screennames when they register or login. Players may, at any time, submit
        information regarding abuse or other offences to our Customer Support Department for Us to investigate.
        Monitoring of the Site We may electronically monitor the Site and the Players of the Site at any time in order
        to ensure compliance with this Agreement and may disclose any information, record or electronic communication of
        a Player of the Site: (a) in compliance with any law, regulation or lawful governmental request; (b) if such
        disclosure is necessary for the continued operation of the Site; or (c) to protect Our rights and/or property of
        Our partners. Inactive Accounts If an Account Holder does not log in to his Account for a period of 180 days,
        his Account will be considered an ‘Inactive Account’, We will remove C$5 from the Account as an administration
        fee each month whilst the Account remains Inactive. The monthly administrative fee is subject to change. If the
        Account has less than C$5 the entire amount will be removed and the balance will be zero. Once the balance
        reaches zero no more Inactivity Fees will apply. Any non redeemed OJO Plus balance will be removed following 180
        days inactivity. Players may recover funds from their inactive accounts by logging in and making a withdrawal
        request. In case of blocked and excluded accounts, players will need to contact Customer Support to recover
        funds. Incomplete Games If you lose internet connection or abandon a game before it is complete (for example
        during a special mode in Slots, or during a hand in single player Blackjack), you will be able to complete the
        game by logging back in and opening the relevant game. If you do not do this within 14 days, the game will be
        closed and any bet will not be returned and any potential winnings will be forfeited. If a game cannot be
        completed due to a fault with our gambling system we will extend the period for logging in to complete the game,
        mentioned in 19.1, to 30 days.
      </p>
      <p>&nbsp;</p>
      <p>
        If we do not manage to solve the issue within 30 days, any bet you made will be returned. Customer Support We
        will provide Support Service for our players Players can send an e-mail to our customer support department
        detailing any questions or queries they may have. To facilitate Account identification the email should be sent
        from the email address which is registered on your Account and should include the Username of the Account in
        question. We reserve the right to perform further identity checks to ensure the person contacting us is the
        Account holder. Responsible Gaming We believe in providing a &apos;protected entertainment environment&apos;. It
        is an important part of our overall customer care program. Gambling in a protected environment should be an
        exciting and pleasurable experience exclusively for adults. We want you to enjoy your experience, so please play
        wisely and within your budget. Whilst the majority of people do gamble within their means, for some gambling can
        become a problem. Deposit Limits: We provide you with the ability to set you own preferred deposit limits within
        the Cashier Section of our Software. Deposit limits can be set for the following periods: Daily Weekly Monthly
        Any decrease in the amount you want to allow yourself to deposit will be applied immediately to your Account.
        However if you wish to increase your Deposit Limits, there will be a 24 hour delay before the change takes
        effect, during which time you can consider if an increase in your deposit limit is appropriate for you. Play
        Limitation Facility: We offer a facility within the casino Software to help you if you feel that your gambling
        is out of control and you would like to restrict the access to your Account. The Player Limitation Facility
        gives you the option to exclude for a range of options from 24 hours to 180 days. You can choose whether you
        want the exclusion to effect only the account you are excluding from or whether it should effect all the
        accounts (current and future) on our license. If you wish to set a longer period please contact customer support
        who can arrange it for you after applying your limitation. If you have an active exclusion period and would like
        to make any changes to it, please contact customer support. Note that any changes which make the condition less
        stringent will require a 24 hours waiting period starting from the time of the request.
      </p>
      <p>&nbsp;</p>
      <p>
        Our Player Limitation options involve a joint commitment from Us and you. According to our policy of protecting
        those with gambling/spending issues, if you choose to exclude from all accounts (current and future) we will
        take reasonable steps to prevent you re-opening your Account or opening new Accounts. However, during the period
        of your exclusion, you must not attempt to re-open your account or to try and open new Accounts. Having
        implemented reasonable checks and safeguards to ensure Responsible Gaming, whilst player limitations are active
        you cannot open an Account, We cannot be held liable to you or any third party if you are able to continue to
        gamble on any account under our license. In that event, any winnings that will be originated during your
        suspension period will be null and void. There are many organisations that can provide support and assistance to
        individuals who develop a problem with gambling. There is a Responsible Gaming section on our Website with all
        the details plus you can contact our staff with any concerns/questions you have. Any deposit limits applied to
        an account are specific for that account. Should you have other accounts on Our license, it is Your
        responsibility to apply Deposit Limits on all accounts that you require them on. When any Player Limitation
        request is received You are forfeiting your right to any rewards or personal offers that were extended to you
        prior to the Limitation. Reality Check is a feature which you can have added to your account by contacting the
        support department and telling them the number of minutes (X) between Reality Checks you would like. You will
        receive a pop up every (X) minutes you have a game open telling you how long you have been playing, how much
        your bets are and how much your payouts are. On each message you will have the opportunity to continue playing
        or log out of the casino. Customer Complaints If you wish to make a claim or complaint, as a first step you
        should as soon as reasonably practicable contact our &apos;Customer Support Department’. We kindly ask you that
        your complaint/claim will be in writing and contain the following information: Account Username Your First Name
        and Surname Detailed explanation of your complaint/claim Specific dates and times related to your
        complaint/claim If, having spoken to a member of the &apos;Customer Support Department’, your query or complaint
        is not resolved, you can ask for the query/complaint to be escalated to a manager or supervisor. Our
        manager/supervisor will look into your query/complaint in more detail and contact you back with our decision
        within 96 hours. If you are still unhappy with the decision made by us, and your issue is related to a Gambling
        Transaction you are welcome to contact eCogra who are an MGA authorized Alternative Dispute Resolution (ADR)
        Company, whose ruling shall be binding. By using the eCogra ADR service you agree to submit your request to them
        in the format requested on their Site and include all the details they request you to include. The eCogra
        policies and procedures, which are detailed on their Site, include the following: Players must ensure that at
        least two weeks have passed since the problem began and that all reasonable attempts have been made to negotiate
        a solution with the approved license holder. Players may not request assistance if the dispute is being or has
        been considered by another certified ADR provider or a court. Players may not request assistance if the dispute
        arose more than one year ago. eCOGRA reserves the right to decline any assistance if in eCOGRA’s opinion the
        dispute is frivolous or vexatious.
      </p>
      <p>&nbsp;</p>
      <p>
        eCOGRA will within 3 weeks of receiving the dispute inform the player and the appropriate approved license
        holder, together with the reason, should eCOGRA decline the dispute on these grounds. You may also lodge a
        complaint before the Malta Gaming Authority; Address: Building SCM 02-03, Level 4, SmartCity Malta, Ricasoli
        SCM1001, Malta, Telephone Number: +356 2546 9000, Email: support.mga@mga.org.mt. A Complaint is considered to be
        a report which includes any aspect of your gaming experience that may be conducted in a manner which is not
        safe, fair or transparent. Affiliations or Interest of Directors, Management and Employees Our employees,
        managers, directors, agents or consultants and their relatives (including spouse, parent, partner, child or
        sibling) are not permitted to register or to participate directly or indirectly in any of the Games. Copyright
        All content within this Site, including, but not limited to, text, trade or service marks, graphics, animations,
        videos, music, audio, logos, icons and images (&apos;Copyright Material&apos;) are the property of Us. Except as
        specified herein, no portion of the materials on these pages may be reprinted, republished or used in any form
        without Our express written permission. The Player obtains no rights to such copyright material and must not use
        it without Our written permission. Links to Third Party Sites This Site may contain hyperlinks to web sites
        operated by persons other than Us. Such hyperlinks are provided for your reference and convenience only.
      </p>
      <p>&nbsp;</p>
      <p>
        You agree not to hold Us responsible for the content or operation of such web sites. Account Termination and
        Suspension If we suspect fraudulent activity, We may suspend your Account, with or without prior notice to you,
        in our sole and absolute discretion. We may, at our sole discretion and for any reason, terminate your Account.
        If we wish to do so, you will be duly notified and our actions will be explained to you before terminating the
        Account. Contractual obligations already made shall be honored. We shall not be liable to you or any third party
        for any claims or damages arising out of any aforementioned termination or suspension of the Account. Notices
        Any notice we give to you will be sent to the email address that you provided when you registered your Account.
        It is your sole responsibility to keep your email address updated and active. Unless otherwise specified herein,
        such notices or other communications shall be deemed received (a) on the date delivered, if sent by email, and
        (b) five business days after being sent, if sent by registered or certified mail. Each of the Parties hereto
        shall be entitled to specify a different address by giving notice as aforesaid to the other Party. Any notice
        You give to Us should be sent to the Customer Support department email address. An email you send which is a
        reply to a transactional email (eg, deposit confirmation email, Rewards confirmation email) is not an accepted
        form of contact and is not deemed to have been received by Us. Governing Law Any dispute arising in respect of,
        or in connection with, this Agreement, its interpretation, execution, performance, or the Parties and their
        relationship, shall be governed and determined exclusively in accordance with the laws of Malta, and the courts
        of Malta shall have sole jurisdiction. Limitation of Liability We shall not be held liable for any damages or
        losses which may arise out of or in connection to any downtime, server or other technical disruptions which
        might arise in operation or transmission, the Site or its content or any misuse of the same or of the services
        offered via the Site, any errors or omissions in content, loss or corruption of data, communication or lines
        failure, unless such loss or damage arises whilst you are benefiting from our services and such loss or damage
        arose due to a failure in our systems. In this event our total aggregate liability shall not exceed the amount
        of the wagers you placed via your Account in respect of the wager that gave rise to the relevant liability or
        the actual amount misplaced in your Account where such amount has been misplaced by us due to a system failure,
        whichever the case may be. We shall not be liable to you or any third party for any contract, tort, negligence,
        or otherwise, for any loss or damage whatsoever arising from save as expressly provided in this clause 29, or in
        any way connected with, Your Account, or any third parties, whether direct or indirect, incidental, special or
        exemplary damages (including, but not limited to, any damages, loss of business profits [including loss of, or
        failure to receive, anticipated winnings], business interruption, loss of business information, loss of data,
        loss of goodwill or reputation or any other loss) arising out of, or in connection with, use of the Site or of
        the services offered via the Site. Subject to clauses 29.1 and 29.4, such limitation of liability shall apply
        whether the damages arise from, but not limited to, use or misuse of, and reliance on, the services, inability
        to use the services, any non-compatibility between the Software and Player software or hardware, or any damages
        caused by or as result of the software, or from the interruption, suspension, or termination of the service
        (including such damages incurred by third parties), or termination of Player Account, to the fullest extent
        permitted by law. Our liability shall not be limited or excluded for: death or personal injury caused by our
        negligence; fraud or fraudulent misrepresentation caused by us; or any liability which cannot be excluded or
        limited under the applicable law. Indemnifications Subject to clause 29, you agree to hold harmless and
        indemnify Us from and against any third party claim arising from, or in any way related to, your use of the
        services, including any liability or expense arising from all claims, losses, actual damages, suits, judgments,
        litigation costs and reasonable legal fees, of any kind and nature. In such a case, We will provide you with a
        notice of such claim, suit or action. Entire Agreement, Modifications and Amendments This Agreement constitutes
        the entire Agreement between the parties with respect to the subject matter contained herein and supersedes all
        previous and contemporaneous agreements, proposals and communications, written or oral, between Us and You. Any
        modifications to the Agreement shall be notified to You and You shall accept the modifications before continuing
        using Our services. If any future modification is unacceptable to you, you have the right to terminate this
        Agreement. Any use of the Site by you after such publication on the site shall be deemed to constitute
        acceptance by you of such amendments, modifications or new conditions. Severability If any provision of this
        Agreement is found or declared to be invalid or unenforceable by any court or other competent authority having
        jurisdiction, such finding or declaration shall not invalidate any other provision hereof, and this Agreement
        shall thereafter continue in full force and effect Segregated Funds All players’ funds will be kept in a
        separate bank account from Our ongoing business funds and the players’ funds will be protected from third
        parties’ claims even in the event of insolvency. The credit institution is aware of the requirement to separate
        the players’ funds from that of our ongoing business funds and will not to attempt to enforce or execute any
        charge, write-off, set-off or other claim against the players’ funds account, or combine the players’ funds with
        any other bank account in respect of any debt of Ours Jackpots All jackpot amounts displayed on the casino Site
        or within the casino Lobby are for information purposes only. The amount of any jackpot is displayed within the
        relevant game window once the game has been launched. When a jackpot is won it is immediately reset on the
        server. This happens in real time however the display can sometimes only reset after a short display. Regardless
        of the Jackpot amount displayed in the game, the server-side Jackpot amount at the time of the win is the award
        which will be honored. Any jackpot payout greater or equal to €50,000 may require an extra 14 days for
        processing. Taxes on Winnings If your jurisdiction requires, You are responsible for reporting your winnings and
        losses to the tax and/or other authorities in your jurisdiction. OJO Plus On every bet you place with Real Money
        a percentage of your bet will be returned to you regardless of the outcome of your bet. The money returned to
        you will feature in your OJOplus balance which you can, subject to the below terms, redeem. Redeeming OJOplus
        balance means transferring the balance in your OJOplus balance to your Real Money Balance.
      </p>
      <p>&nbsp;</p>
      <p>
        This can only be actioned by You by logging into Your PlayOJO account and following the correct steps. Your
        OJOplus balance will show 2 decimal places. This means that an increase in your balance may not be noticeable to
        you on every bet, however the correct sum is maintained in the casino software For example, if your Real Money
        bet accrues you 0.003 in OJO Plus balance we are counting this amount but you will not see it until it reaches
        an amount which can be reflected in 2 decimal places. We always round down to the nearest 2 decimal place
        number. For example you may have 0.0134 in your OJO Plus balance but you will see and can redeem only 0.01. You
        can Redeem amounts which are 2 decimal places. For example, if you have 0.0199 you will be able to redeem 0.01
        (you will have 0.0099 remaining but it will not be visible to you, as per clause 36.3). You can only redeem your
        full OJOplus balance. We publish on our site the exact percentage retuned on each game. We in our sole
        discretion may alter this percentage from time to time without prior notice to you. Prohibited Practices
        Prohibited Practices are not permitted and will constitute a material breach of the Terms of Use. We will take
        all reasonable steps to prevent and detect Prohibited Practices and to identify the relevant players concerned
        if they occur. Subject to the above, however, we will not be liable for any loss or damage which You may incur
        as a result of any Prohibited Practices, and we alone will decide any action we take in respect of any
        Prohibited Practices. You agree that You shall not participate in or be connected with any form of Prohibited
        Practice. If we have reasonable grounds to believe that You have participated in or have been connected with any
        form of Prohibited Practice (either detected by us or by our gaming partners and our other suppliers) of any
        fraud, cheating and collusion practices which are used in the gambling and gaming industry (including but not
        limited to &quot;Wonging&quot;, “Perfect Pair” Card Counting, Dragon Tiger &quot;Suited Tie&quot; Card Counting
        or any other Card Counting techniques) or If You have placed bets and/or played online games with any other
        online provider of gambling services and are suspected (as a result of such play) of any equivalent to any
        Prohibited Practice on those other gambling services, or other improper activity. We reserve the right, in
        respect of Your Account (and/or any other account held by You with an Operator Group company) to withhold the
        whole or part of the balance and/or recover from the account the amount of any deposits, pay-outs, bonuses or
        winnings which have been affected by or are in any way attributable to any of the event(s) The rights set out in
        this paragraph do not affect any other rights (including any common law rights) we may have against You, under
        the Terms of Use or otherwise.
      </p>
    </Container>
  );
};
