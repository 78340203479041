import { Container } from '@mui/system';
import { NavLink } from 'react-router-dom';

const Footer = () => {
  return (
    <Container>
      <ul className="footer">
        <li>
          <NavLink to="/privacy"> Privacy Policy</NavLink>
        </li>
        <li>
          <NavLink to="/terms">Terms &amp; Conditions</NavLink>
        </li>
      </ul>
    </Container>
  );
};

export default Footer;
