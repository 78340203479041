export const ROUTES = {
  home: '/',
  betMGM: 'play-online-casino-instead-of-the-lottery/betMGM',
  caesars: 'play-online-casino-instead-of-the-lottery/caesars',
  fanduel: 'play-online-casino-instead-of-the-lottery/fanduel',
  betRivers: 'play-online-casino-instead-of-the-lottery/betrivers',
  terms: '/terms',
  privacy: '/privacy',
  notFound: '/404',
};
