import BetMgm from 'components/BetMgm';
import BetRivers from 'components/BetRivers';
import Caesars from 'components/Caesars';
import FanDuel from 'components/Fanduel';
import Main from 'components/Main';
import NotFound from 'components/NotFound';
import { Privacy } from 'components/Privacy';
import { Terms } from 'components/Terms';
import { ROUTES } from 'constants/routes';
import Layout from 'layouts/Layout';
import { Navigate, useRoutes } from 'react-router-dom';

const Routes = () => {
  return useRoutes([
    {
      element: <Layout />,
      children: [
        {
          path: ROUTES.home,
          element: <Main />,
        },
        {
          path: ROUTES.betMGM,
          element: <BetMgm />,
        },
        {
          path: ROUTES.caesars,
          element: <Caesars />,
        },
        {
          path: ROUTES.fanduel,
          element: <FanDuel />,
        },
        {
          path: ROUTES.betRivers,
          element: <BetRivers />,
        },
        {
          path: ROUTES.notFound,
          element: <NotFound />,
        },
        {
          path: ROUTES.terms,
          element: <Terms />,
        },
        {
          path: ROUTES.privacy,
          element: <Privacy />,
        },

        {
          path: '*',
          element: <Navigate to={ROUTES.notFound} replace />,
        },
      ],
    },
  ]);
};

export default Routes;
