export const Brandlist = [
  {
    id: 1,
    name: 'BetMGM',
    url: 'https://captrkr.com/track/0e753fe3-1e80-4ea4-bdf7-e64dcdaf96d7?type=ppc&s1=TB01BEM620MI&s5=',
    bonusText: '100% Bonus up to $1000 + $25 Token',
  },
  {
    id: 2,
    name: 'Caesars',
    url: 'https://captrkr.com/track/f330c8cd-03ac-4f90-879b-26bc858c3a0b?type=ppc&s1=TB01CAS520MI&s5=',
    bonusText: 'Match up to $2,000',
  },
  {
    id: 3,
    name: 'FanDuel',
    url: 'https://captrkr.com/track/f2079fd8-4ebb-4804-aacf-c2ba5410a507?type=ppc&s1=TB01FAL650MI&s5=',
    bonusText: 'Get up to $1000 back plus $100 Casino Bonus',
  },
  {
    id: 4,
    name: 'BetRivers',
    url: 'https://captrkr.com/track/cd929ffd-4b8c-4647-812a-ec4d1aa5ab38?type=ppc&s1=TB01BES550MI&s5=',
    bonusText: '100% Bonus up to $250 / Deposit code: CASINOBACK',
  },
];
