import React from 'react';
import ReactDOM from 'react-dom/client';
import './input.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import TagManager, { TagManagerArgs } from 'react-gtm-module';

const tagManagerArgs: TagManagerArgs = {
  gtmId: 'GTM-PKZZ7ZV',
};

TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
);
